html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: hidden; 
}

.banner-section {
    position: relative;
    width: 100%;
    height: 100vh; /* Full viewport height */
    overflow: auto; /* Allow vertical overflow if content exceeds viewport */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e7e2dc; /* Set background color */
    position: relative;
}

.container-fluid {
    height: 100%;
}

.highlight {
    color: #ff6347;
}


.robot-img {
    width: 100%;
    max-width: 300px;
}

.text-section h2 {
    margin-bottom: 1px; /* Adjust this value to increase or decrease the spacing */
}

.text-section p {
    margin-top: 10px; /* Adjust this value if you want to control the spacing above the paragraph as well */
}

.custom-large-btn {
    padding: 20px 40px; /* Adjust padding to increase button size */
    font-size: 1.5rem; /* Adjust font size to increase button text size */
    /* Add any other styles as needed */
}

.text-section {
    overflow: hidden;
}

.banner-left {
    margin-bottom: 1px;
}

.banner-right {
    margin-top: 10px;
}
.custom-h2 {
    font-size: 1.5rem; /* Default font size for larger screens */
}
.banner-video {
    position: absolute;
    top: -1.5%;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    
}

.banner-right-custom {
    background: url("../images/element/robot-3.png") no-repeat center / 90%;
    height: calc(125vh - 100px);
    margin-top: 100px;
    margin-left: 100px;
    padding: px;
    overflow: hidden; /* Ensure overflow is visible */
}
.no-padding {
    padding: 0 !important;
    margin: 0 !important;
}
.custom-btn {
    padding: 1rem 2rem; /* Adjust padding for size */
    font-size: 1.25rem; /* Adjust font size */
}




@media (max-width: 768px) {
    .banner-right-custom {
        height: auto; /* Adjust height for smaller devices */
        margin-top: 0; /* Remove negative margin for smaller devices */
        margin-left: 0; /* Remove left margin for smaller devices */
        padding: 20px; /* Adjust padding for smaller devices */
        background-size: cover; /* Change background size for smaller devices */
        overflow: visible; /* Ensure overflow is visible */
    }
}



/* Media Queries */
@media (max-width: 767.98px) {
    .banner-section {
        padding-top: 80px; /* Add padding to the top for mobile devices */
        
    }
    .banner-left {
        margin-left: 10px !important; /* Disable left margin for mobile devices */
    }
    .text-section {
        text-align: left;
    }
    .custom-h2 {
        font-size: 1.2rem; /* Adjust font size for tablets and smaller devices */
    }
}

@media (min-width: 480px) and (max-width: 767.98px) {
    /* Add your specific CSS for xs screens */
    .banner-left {
        margin-left: 10px !important; /* Adjust margin-left for smaller screens */
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    /* Add your specific CSS for sm screens */
    .banner-left {
        margin-left: 15px !important; /* Adjust margin-left for small screens */
    }
}

@media (min-width: 768px) {
    /* Add your specific CSS for md screens */
    .banner-left {
        margin-left: 25px !important; /* Restore margin-left for medium screens and up */
    }
}

@media (min-width: 992px) {
    /* Add your specific CSS for lg screens */
    .banner-left {
        margin-left: 25px !important; /* Ensure margin-left for large screens */
    }
}

@media (min-width: 1200px) {
    /* Add your specific CSS for xl screens */
    .banner-left {
        margin-left: 25px !important; /* Ensure margin-left for extra-large screens */
    }
}

@media (min-width: 1600px) {
    /* Add your specific CSS for xxl screens */
    .banner-left {
        margin-left: 25px !important; /* Ensure margin-left for extra-extra-large screens */
    }
}

@media (max-width: 1600px) {
    .banner-right {
        height: calc(100vh - 1px);
    }
    .banner-left .text-section h1,
    .banner-left .text-section h2,
    .banner-left .text-section p {
        font-size: 1.2rem;
    }
}

@media (max-width: 1200px) {
    .banner-right {
        height: calc(80vh - 1px);
    }
    .banner-left .text-section h1,
    .banner-left .text-section h2,
    .banner-left .text-section p {
        font-size: 1rem;
    }
}

@media (max-width: 992px) {
    .banner-right {
        height: calc(70vh - 1px);
    }
    .banner-left .text-section h1,
    .banner-left .text-section h2,
    .banner-left .text-section p {
        font-size: 0.9rem;
    }
}

@media (max-width: 768px) {
    .banner-right {
        height: calc(60vh - 1px);
    }
    .banner-left .text-section h1,
    .banner-left .text-section h2,
    .banner-left .text-section p {
        font-size: 0.8rem;
    }
}

@media (max-width: 576px) {
    .banner-right {
        height: calc(50vh - 1px);
    }
    .banner-left .text-section h1,
    .banner-left .text-section h2,
    .banner-left .text-section p {
        font-size: 0.7rem;
    }
}

@media (max-width: 480px) {
    .banner-right {
        height: calc(40vh - 1px);
    }
    .banner-left .text-section h1,
    .banner-left .text-section h2,
    .banner-left .text-section p {
        font-size: 2.2rem;
    }
    .banner-left .text-section h2 {
        font-size: 1.5rem; /* Adjust font size for tablets and smaller devices */
    }
}



@media (max-width: 767px) {
    .second-banner-left {
        padding-left: 25px;
        text-align: center;
    }

}

